import { computed, watch } from 'vue';
import { useAccountStore } from 'src/stores/account';
import { useCentralStore } from 'src/stores/central';
import { useSearchStore } from 'src/stores/search';
import { useRoute, useRouter } from 'vue-router';
import { debounceTime, filter, tap } from 'rxjs/operators';
import { rxInitSubjectCallback, useObservable } from 'src/plugins/rxjs/base';
import { ThreadingQueryPlugin } from 'src/features/serverConnector/threading';
import { AppGeneralVariables } from 'src/plugins/general/variables';
// Meta update for landing pages
const InitializePlugin = () => {
    const { appMode, styleType } = AppGeneralVariables();
    const accountStore = useAccountStore();
    const centralStore = useCentralStore();
    const searchStore = useSearchStore();
    const mapVal = computed(() => searchStore.map);
    const user = computed(() => accountStore.user);
    const refreshingUser = computed(() => accountStore.refreshingUser);
    const requeryInit = computed(() => centralStore.requeryInit);
    // block query spams
    const queryingInProgress = computed({
        get: () => centralStore.requeryingInit,
        set: (newVal) => {
            centralStore.setRequeryingInit(newVal);
        }
    });
    // i have no idea why this is set to true at the start
    // hence this is a fix for that
    queryingInProgress.value = false;
    const { subject: initializer$ } = rxInitSubjectCallback();
    function threadingInitCallback({ newValue }) {
        initializer$.next(newValue);
    }
    function threadingInitCallbackError(err) {
        queryingInProgress.value = false;
        if (!user.value)
            return;
        if (refreshingUser.value)
            return;
        const errorCode = err.code;
        if ([401, 403].indexOf(errorCode || 0) === -1)
            return;
        void accountStore.refresh();
    }
    const { threadingTaskFunction } = ThreadingQueryPlugin('init', threadingInitCallback, threadingInitCallbackError, false);
    const $route = useRoute();
    const countryMap = {
        'sg': 'singapore',
        'my': 'malaysia'
    };
    const { subject: debouncedQuery$ } = rxInitSubjectCallback();
    useObservable(debouncedQuery$.pipe(tap(() => {
        queryingInProgress.value = true;
    }), debounceTime(500), tap((loading) => {
        threadingTaskFunction({
            data: {
                url: user.value
                    ? 'user_auth/auth_init_wif_auth'
                    : 'user_auth/auth_init_wo_auth',
                type: 'list',
                urlType: 'common',
                loading,
                params: {
                    filter: {
                        type: appMode.value
                    }
                }
            },
            type: 'api'
        });
    })));
    function initializeApp(loading = false) {
        // do not call when the user is refreshing
        if (user.value && refreshingUser.value)
            return;
        // block calls to backend while previous query is still running
        if (queryingInProgress.value)
            return;
        // debounce query to prevent short burst of
        // multiple calls failing
        debouncedQuery$.next(loading);
    }
    const $router = useRouter();
    useObservable(initializer$.pipe(debounceTime(500), tap(() => {
        // release block
        queryingInProgress.value = false;
    }), tap((v) => {
        // general variables
        centralStore.toggleCashBack(Number(v.default_system_value_detail.cashback_pct));
        centralStore.toggleMaxCashBack(Number(v.default_system_value_detail.max_cashback_amt));
    }), tap((v) => {
        var _a;
        if (v.user_current_country_detail && ((((_a = v.user_current_country_detail) === null || _a === void 0 ? void 0 : _a.user_current_country_code) || "").toLowerCase() !==
            ($route.params.country || '').toString())) {
            $router.push({
                query: Object.assign({}, $route.query),
                params: {
                    country: v.user_current_country_detail.user_current_country_code.toLowerCase()
                }
            });
        }
    }), tap((v) => {
        // country related
        const { user_current_country_detail } = v;
        if (!user_current_country_detail)
            return;
        centralStore.toggleCountry(user_current_country_detail.user_current_country_pk.toString());
        const center = user_current_country_detail.user_current_country_center;
        const zoomVal = styleType.value === 'desktop'
            ? user_current_country_detail.user_current_country_zoom
            : user_current_country_detail.user_current_country_zoom_mobile;
        searchStore.setMapCenter(center);
        searchStore.setMapZoom(zoomVal);
        if (!mapVal.value) {
            searchStore.updateMap({
                zoom: zoomVal,
                center: center
            });
        }
        centralStore.toggleTimezone(user_current_country_detail.user_current_country_pytz_timezone ||
            Intl.DateTimeFormat().resolvedOptions().timeZone);
    }), filter(() => {
        if (!user.value) {
            // fully initialized for non logged in users
            centralStore.toggleInitialized(true);
        }
        return user.value !== null;
    }), tap((v) => {
        const { user_detail, user_current_country_detail } = v;
        // update user base data
        accountStore.updateUser({
            data: Object.assign(Object.assign({}, accountStore.flattenUserDetails(user_detail)), { 
                // phone
                phone_details: {
                    country_code: user_detail.user_phone_detail.phone_country_code,
                    area_code: user_detail.user_phone_detail.phone_area_code,
                    phone_num: user_detail.user_phone_detail.phone_num,
                    disable_popups: user_detail.user_phone_detail.phone_disable_popups,
                }, 
                // country
                current_country_detail: {
                    current_country_code: user_current_country_detail.user_current_country_code,
                    current_country_currency: user_current_country_detail.user_current_country_currency,
                    current_country_name: user_current_country_detail.user_current_country_name,
                    current_country_pk: user_current_country_detail.user_current_country_pk,
                } })
        });
        if ($route.params.country == "gen") {
            $router.push({
                params: {
                    country: user_current_country_detail.user_current_country_code.toLowerCase()
                }
            });
        }
    }), tap(({ user_detail }) => {
        // update misc data (i.e counts and credits)
        accountStore.setFlexCredits(user_detail.user_per_country_reward_detail || []);
        accountStore.setBookings(user_detail.user_upcoming_no_of_booking || 0);
        accountStore.setOrderReceivable(user_detail.user_order_pending_guest_review_count || 0);
    }), tap(({ host_detail }) => {
        accountStore.setOrder(host_detail.host_no_of_order_to_approve || 0);
    }), tap(() => {
        centralStore.toggleInitialized(true);
    })));
    watch(requeryInit, (newValue, oldValue) => {
        if (newValue.ver === oldValue.ver)
            return;
        initializeApp(newValue.loading);
    });
    // when user status change
    watch(user, (newValue, oldValue) => {
        if (oldValue && newValue && oldValue.user_pk === newValue.user_pk)
            return;
        initializeApp(false);
    }, { immediate: true });
    // requery after user refresh login status
    watch(refreshingUser, (newValue, oldValue) => {
        if (!oldValue || newValue)
            return;
        initializeApp(false);
    });
    initializeApp(false);
    return { initializeApp };
};
export { InitializePlugin };
