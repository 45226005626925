import { computed, watch } from 'vue';
import { rxInitSubjectCallback, useObservable } from 'src/plugins/rxjs/base';
import { filter, tap } from 'rxjs/operators';
import { useThreadingStore } from 'src/stores/threading';
import { useRoute } from 'vue-router';
/**
 * Standard plugin to call the backend using threading.
 * Contains the standard checks to
 * ensure the data returned is updated
 *
 * @param endState State
 * @param threadingResultsCallBack when results are returned, and after the unique checks
 *  is passed, call this function
 * @param immediate whether to query backend on create
 * @param threadingErrorCallBack when the backend call is returned as error, call this function
 * @returns void
 */
const ThreadingQueryPlugin = (endState, threadingResultsCallBack, threadingErrorCallBack, immediate = false) => {
    const $route = useRoute();
    const threadingStore = useThreadingStore();
    /**
     * Query backend
     */
    function threadingTaskFunction(params) {
        /**
         * Standard data sent to vuex for threading
         */
        void threadingStore.threadTasks(Object.assign(Object.assign({}, params), { data: Object.assign(Object.assign({}, params.data), { params: Object.assign(Object.assign({}, params.data.params), (params.data.url.includes('user_auth/auth_init')
                    ? {}
                    : {
                        country_code: ($route.params.country || '').toString() || 'sg'
                    })) }), type: 'api', endState }));
    }
    /**
     * When data is returned
     */
    const threadedCallData = computed(() => threadingStore.data[endState]);
    const { subject: threadingListener$ } = rxInitSubjectCallback();
    /**
     * Listen to when the threading data is returned. Push data to
     * the custom callback when its verified that its a new data
     * by comparing the thread_id
     */
    useObservable(threadingListener$.pipe(filter(({ newValue }) => newValue !== null && newValue !== undefined), filter(({ newValue, oldValue }) => {
        if (!oldValue)
            return true;
        return newValue.thread_id !== oldValue.thread_id;
    }), tap(({ newValue, oldValue }) => threadingResultsCallBack({ newValue, oldValue }))));
    watch(threadedCallData, (newValue, oldValue) => {
        threadingListener$.next({ newValue, oldValue });
    }, { immediate });
    /**
     * When thread returns error, call the error callback
     */
    const threadedCallDataError = computed(() => threadingStore.error[endState]);
    watch(threadedCallDataError, (newValue) => {
        // do not run below after error is cleared
        if (!newValue)
            return;
        threadingErrorCallBack(newValue);
        threadingStore.clearError(endState);
    });
    return {
        threadingTaskFunction,
        threadedCallData
    };
};
export { ThreadingQueryPlugin };
