var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { computed } from 'vue';
import useQuasar from 'quasar/src/composables/use-quasar/use-quasar.js';
import { useAccountStore } from 'src/stores/account';
import { useCentralStore } from 'src/stores/central';
import { StandardAlertsPlugin } from 'src/plugins/alert/alertPlugin';
import { AxiosDataParser } from 'src/plugins/axios/axiosParser';
import { tteCalculator } from 'src/features/account/methods/tteCalculator';
import { useRoute } from 'vue-router';
/**
 * standard way to call backend using axios
 * with the check if user is authenticated
 * @returns wrapped axios caller with time to expire
 */
const axiosCallerPlugin = () => {
    const $q = useQuasar();
    const $route = useRoute();
    const { successAlert, errorAlert } = StandardAlertsPlugin();
    const accountStore = useAccountStore();
    const centralStore = useCentralStore();
    const user = computed(() => accountStore.user);
    const userMisc = computed(() => accountStore.userMisc);
    function axiosCall(data) {
        return __awaiter(this, void 0, void 0, function* () {
            const axiosLib = new AxiosDataParser(Object.assign(Object.assign({}, data), { params: Object.assign(Object.assign({}, data.params), { country_code: ($route.params.country || '').toString() || 'sg' }) }), {
                timeToExpire: tteCalculator(user.value) || 0,
                customAuthHeader: null,
                userMisc: userMisc.value
            });
            const { loading, loadingMessage } = axiosLib.axiosLoading();
            if (loading) {
                $q.loading.show({
                    message: loadingMessage,
                    spinnerSize: 250,
                    spinnerColor: 'accent'
                });
            }
            if (data.url === 'user_auth/auth_init_wif_auth') {
                tteCalculator(user.value);
            }
            // if user is offline
            if (!centralStore.online) {
                errorAlert('There are some errors with your internet connection. Please try again later');
                $q.loading.hide();
                return {
                    success: false,
                    error: {
                        message: 'Is offline',
                        code: 0,
                        statusText: '',
                        reason: ''
                    },
                    data: null
                };
            }
            const axiosResponse = yield axiosLib.backendCall();
            const axiosError = axiosResponse.error;
            if (axiosError !== null) {
                if (([401, 403].indexOf(axiosError.code) > -1 || (typeof axiosError.message === 'string' &&
                    axiosError.message === 'User is not authenticated')) && data.state !== 'init') {
                    // check if user is authenticated. refresh token if necessary
                    centralStore.setRequeryInit();
                }
                else if (axiosError.code === 500) {
                    errorAlert('Server error. Please contact the customer service if this persists');
                }
                else if (axiosError.code === 408) {
                    errorAlert('Request timeout. Please try again later');
                }
                else if (axiosError.code === 404) {
                    errorAlert('Not found');
                }
                else if (typeof axiosError.reason === 'string') {
                    errorAlert(axiosError.reason);
                }
            }
            else {
                // show alert on success if its specified
                if (data.alert && data.message) {
                    successAlert(data.message);
                }
            }
            if (loading) {
                $q.loading.hide();
            }
            return axiosResponse;
        });
    }
    return {
        axiosCall
    };
};
export { axiosCallerPlugin };
