import { computed } from 'vue';
import { useAccountStore } from 'src/stores/account';
import { useCentralStore } from 'src/stores/central';
import { useSizeStore } from 'src/stores/size';
import useQuasar from 'quasar/src/composables/use-quasar/use-quasar.js';
const AppGeneralVariables = () => {
    const $q = useQuasar();
    const accountStore = useAccountStore();
    const centralStore = useCentralStore();
    const sizeStore = useSizeStore();
    const appMounted = computed(() => centralStore.appMounted);
    /**
     * Viewport height
     */
    const innerHeight = computed(() => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const platformIs = $q.platform.is;
        if (platformIs.capacitor && platformIs.ios) {
            return $q.screen.height;
        }
        return sizeStore.innerHeight;
    });
    /**
     * App mode. only have 'getspaces' for now
     */
    const appMode = computed(() => {
        return centralStore.mode || process.env.appMode;
    });
    /**
     * Style type. 'app' for mobile app, 'desktop' for desktop, 'mobile' for mobile web
     */
    const styleType = computed(() => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const platformIs = $q.platform.is;
        const prodType = process.env.prodType;
        const forceAppMode = ['false', false].indexOf($q.cookies.get('force-app-mode') || false) === -1 && (prodType !== 'prod');
        if (platformIs.capacitor || forceAppMode) {
            return 'app';
        }
        else if (platformIs.desktop) {
            return 'desktop';
        }
        // return 'app'
        return 'mobile';
    });
    /**
     * Check if user is host
     */
    const isHost = computed(() => {
        const user = accountStore.user;
        const userMisc = accountStore.userMisc;
        if (!user) {
            return false;
        }
        if (user.profile_role_detail) {
            const hostRole = user.profile_role_detail.filter(items => {
                var _a;
                return items.role_name === 'host' && (items.role_country_code == (((_a = userMisc === null || userMisc === void 0 ? void 0 : userMisc.current_country_detail) === null || _a === void 0 ? void 0 : _a.current_country_code) || "SG"));
            });
            return (hostRole.length > 0);
        }
    });
    /**
     * Check if user is admin
     */
    const isAdmin = computed(() => {
        const user = accountStore.user;
        if (user) {
            if (user.profile_role_detail) {
                const hostRole = user.profile_role_detail.filter(items => {
                    return items.role_name === 'admin';
                });
                return (hostRole.length > 0);
            }
        }
        return false;
    });
    // standard thumb style
    // of q-scroll-area
    const thumbStyle = {
        right: '2px',
        borderRadius: '5px',
        backgroundColor: '#a1a1a1',
        width: '5px',
        opacity: 0.75
    };
    return {
        appMounted,
        isAdmin,
        isHost,
        innerHeight,
        appMode,
        styleType,
        thumbStyle
    };
};
export { AppGeneralVariables };
